global.jQuery = global.$ = window.jQuery = require('jquery');
var Swiper = require('swiper');
require('easing');

var topHero = new Swiper('.swiper-container', {
	effect: 'fade',
	loop: true,
	speed : 2000,
	autoplay: {
		delay: 3000,
	},
	pagination: {
		el: '.slide_pagination',
		type: 'bullets',
		clickable: true
	},
	navigation: {
		nextEl: '.slide_next',
		prevEl: '.slide_prev',
	}
});